
@import url("https://unpkg.com/@webpixels/css@1.1.5/dist/index.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/bootstrap-icons/1.4.0/font/bootstrap-icons.min.css");
@import url("https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap");

.nav-link.active>i:before {
    color: #fdd640;
}

button .loginButtonText {
    margin: 5px 0;
}

.pagination .page-item.active .page-link {
    border: 1px solid #27293b;
    background: #27293b;
    color: #ffffff;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}


.checkbox-lg .custom-control-label::before,
.checkbox-lg .custom-control-label::after {
    top: 0.8rem;
    width: 1.55rem;
    height: 1.55rem;
}

.checkbox-lg .custom-control-label {
    padding-top: 8px;
    padding-left: 6px;
}

.checkbox-xl .custom-control-label::before,
.checkbox-xl .custom-control-label::after {
    top: 1.2rem;
    width: 1.85rem;
    height: 1.85rem;
}

.checkbox-xl .custom-control-label {
    padding-top: 23px;
    padding-left: 10px;
}

.loading {
    position: fixed;
    z-index: 9999;
    height: 2em;
    width: 2em;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

/* Transparent Overlay */
.loading:before {
    content: "";
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(rgba(20, 20, 20, 0.8), rgba(0, 0, 0, 0.8));

    background: -webkit-radial-gradient(rgba(20, 20, 20, 0.8),
            rgba(0, 0, 0, 0.8));
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
    /* hide "loading..." text */
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
}

.loading:not(:required):after {
    content: "";
    display: block;
    font-size: 10px;
    width: 1em;
    height: 1em;
    margin-top: -0.5em;
    -webkit-animation: spinner 150ms infinite linear;
    -moz-animation: spinner 150ms infinite linear;
    -ms-animation: spinner 150ms infinite linear;
    -o-animation: spinner 150ms infinite linear;
    animation: spinner 150ms infinite linear;
    border-radius: 0.5em;
    -webkit-box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0,
        rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0,
        rgba(255, 255, 255, 0.75) 0 1.5em 0 0,
        rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0,
        rgba(255, 255, 255, 0.75) -1.5em 0 0 0,
        rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0,
        rgba(255, 255, 255, 0.75) 0 -1.5em 0 0,
        rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
    box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0,
        rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0,
        rgba(255, 255, 255, 0.75) 0 1.5em 0 0,
        rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0,
        rgba(255, 255, 255, 0.75) -1.5em 0 0 0,
        rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0,
        rgba(255, 255, 255, 0.75) 0 -1.5em 0 0,
        rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
}

@-webkit-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-moz-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-o-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@media only screen and (max-width: 1024px) {
    div#sidebarCollapse {
        position: fixed;
        top: 0;
        bottom: 0;
        transform: translateX(-150%);
        width: 280px;
        z-index: 99999;
        transition: transform 0.3s ease-in-out;
        display: block !important;
        box-shadow: 0px 0px 8px rgb(0 0 0 / 50%);
        height: 100% !important;
        overflow: auto !important;
    }

    div#sidebarCollapse.show {
        transform: translateX(0);
    }

}