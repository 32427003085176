// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
// $font-size-base: 0.9rem;
$line-height-base: 1.6;

.row-height {
    min-height: 100vh;
}
.largeFont {
    font-size: xxx-large;
}
.fadeColor{
 color: #9194B3;
}